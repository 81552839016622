var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pg_content_container flex flex-1 flex-col gap-6"},[_c('div',{staticClass:"flex flex-col gap-2"},[_c('div',{staticClass:"flex items-center justify-between gap-6"},[_c('div',{staticClass:"flex items-center gap-4"},[_c('Icon',{staticClass:"h-8 w-8",attrs:{"icon-id":"icon_coach","data-id":"title-icon"}}),_c('h1',{staticClass:"m-0 text-3xl font-bold normal-case text-secondary-500"},[_vm._v("Coach momenten")])],1),(_vm.rooster && _vm.rooster.status === 'concept')?_c('button',{class:_vm.buttonVariants({
						variant: 'outline',
						class: '-my-1 border-secondary-500 bg-transparent text-secondary-500',
					}),attrs:{"type":"button"},on:{"click":function($event){return _vm.openOverlay({
						name: 'coach-moment_create',
						data: { vestigingId: _vm.gymId },
					})}}},[_vm._v(" Moment inplannen ")]):_vm._e()]),_c('p',{staticClass:"m-0 text-base font-normal text-secondary-400"},[_vm._v(" Hier kun je coach momenten beheren. ")])]),_c('rooster-bar'),_c('hr',{staticClass:"m-0 border-secondary-50"}),_c('div',{staticClass:"flex flex-col gap-2"},[_c('div',{staticClass:"flex items-center justify-between gap-6"},[_c('div',{staticClass:"flex flex-col"},[_c('h2',{staticClass:"m-0 text-lg font-bold text-secondary-500"},[_vm._v("Planning")]),_c('span',{staticClass:"text-sm font-normal text-secondary-400"},[(_vm.gymId === 'all')?[_vm._v(" "+_vm._s(_vm.allRooster.length)+" ingeplande momenten ")]:[_vm._v(" "+_vm._s(_vm.gymRooster.length)+" ingeplande momenten in deze vestiging ("+_vm._s(_vm.allRooster.length)+" totaal) ")]],2)]),_c('div',{staticClass:"flex flex-col gap-1"},[_c('label',{class:_vm.labelVariants({ class: 'text-sm text-secondary-400' }),attrs:{"for":"form_coach-moment_vestiging"}},[_vm._v(" Filter op vestiging: ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.gymId),expression:"gymId"}],staticClass:"m-0 text-secondary-500",attrs:{"id":"form_coach-moment_vestiging"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.gymId=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"disabled":""},domProps:{"value":undefined}},[_vm._v("Selecteer een vestiging...")]),_c('option',{attrs:{"value":"all"}},[_vm._v("Alle")]),_c('hr'),_vm._l((_vm.vestigingen),function(vestiging){return _c('option',{key:vestiging.id,domProps:{"value":vestiging.id}},[_vm._v(" "+_vm._s(vestiging.naam)+" ")])})],2)])]),_c('table',{staticClass:"table-fixed border-collapse"},[_c('thead',[_c('tr',[_c('th',{class:_vm.labelVariants({
								class: 'w-1/3 p-3 pb-3 pl-4 pt-0 text-left text-sm text-secondary-300',
							})},[_vm._v(" Dag ")]),_c('th',{class:_vm.labelVariants({ class: 'w-1/6 p-3 pb-3 pt-0 text-left text-sm text-secondary-300' })},[_vm._v(" Tijd ")]),_c('th',{class:_vm.labelVariants({ class: 'w-1/3 p-3 pb-3 pt-0 text-left text-sm text-secondary-300' })},[_vm._v(" Locatie ")]),_c('th',{staticClass:"w-1/6 p-3 pr-4"})])]),_c('tbody',{staticClass:"bg-white"},_vm._l((_vm.gymId === 'all' ? _vm.allRooster : _vm.gymRooster),function(slot){return _c('tr',{key:slot.id},[_c('td',{staticClass:"p-3 pl-4 text-sm"},[_vm._v(" "+_vm._s(_vm.DAYS_LABEL[slot.day])+" ")]),_c('td',{staticClass:"p-3 text-sm tabular-nums"},[_c('time',{attrs:{"datetime":slot.time}},[_vm._v(" "+_vm._s(_vm.formatRange(slot))+" ")])]),_c('td',{staticClass:"p-3 text-sm"},[_vm._v(" "+_vm._s(_vm.getVestigingName(slot.gymId) || slot.gymId)+" ")]),_c('td',{staticClass:"flex items-center justify-end gap-4 p-3 pr-4"},[(_vm.rooster && _vm.rooster.status === 'concept')?[_c('button',{class:_vm.buttonVariants({
										variant: 'naked',
										size: 'icon',
										class: '-my-1.5 h-6 w-6 p-1 text-secondary-300',
									}),attrs:{"type":"button"},on:{"click":function($event){return _vm.openOverlay({
										name: 'coach-moment_update',
										data: slot,
									})}}},[_c('PencilSquare')],1),_c('button',{class:_vm.buttonVariants({
										variant: 'naked',
										size: 'icon',
										class: '-my-1.5 h-6 w-6 p-1 text-secondary-300',
									}),attrs:{"type":"button"},on:{"click":function($event){_vm.openModal({
										name: 'coach-moment_delete',
										data: {
											id: slot.id,
											day: slot.day,
											time: slot.time,
											gymId: slot.gymId,
											gymName: _vm.getVestigingName(slot.gymId) || slot.gymId,
										},
									})}}},[_c('Trash')],1)]:_vm._e()],2)])}),0)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }